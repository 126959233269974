import React, { useState, useRef, useEffect } from 'react';
import { string } from 'prop-types';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import uploadImg from '../../assets/upload.png';
import { Field } from 'react-final-form';
import css from './SignupForm/SignupForm.module.css';
import { IconSpinner, ValidationError } from '../../components';

const UserFieldImageUpload = props => {
  const [image, setImage] = useState('');
  const [fileName, setFileName] = useState('');
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadImageError, setUploadImageError] = useState(null);

  const fileInputRef = useRef(null);

  const { name, placeholder, dimensionInfo } = props;
  const { showConfig = {} } = props?.fieldConfig || {};

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const onChange = async (e, input) => {
    const file = e.target.files[0];
    if (file) {
      const validTypes = ['image/jpeg', 'image/png', 'image/jpg'];
      const maxSize = 20 * 1024 * 1024; // 20 MB

      if (!validTypes.includes(file.type)) {
        setUploadImageError("Only JPG, JPEG, and PNG files are allowed.");
        return;
      }

      if (file.size > maxSize) {
        setUploadImageError("File size exceeds the 20 MB limit.");
        return;
      }

      setUploadInProgress(true);
      setUploadImageError(null);

      try {
        await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            setTimeout(() => {
              resolve();
            }, 1000);
          };
          reader.onerror = () => {
            reject(new Error("File upload failed"));
          };
          reader.readAsDataURL(file);
        });

        setImage(URL.createObjectURL(file));
        setFileName(file.name);
        input.onChange(file);
      } catch (error) {
        setUploadImageError(error.message);
      } finally {
        setUploadInProgress(false);
      }
    }
  };

  const { value } = props
  useEffect(() => {
    if (value) {
      setImage(typeof value === 'string' ? value : URL.createObjectURL(value));
      setFileName(value.name);
    }
  }, [value])

  return (
    <Field
      {...props}
      className={css.uploadFileContainer}
      accept={['image/jpeg', 'image/png', 'image/jpg']}
      id={name}
      name={name}
      label={
        <div className={css.avatarContainer}>
          <div className={css.avatarPlaceholder}>
            <div className={css.avatarPlaceholderText}>
              {showConfig.label + '*'}
            </div>
          </div>
        </div>
      }
      type="file"
    >
      {({ accept, id, input, label, meta }) => {

        const { name, type } = input;
        const { invalid, error, touched } = meta;
        const hasError = !!(invalid && error && touched);

        const errorText = error;
        const fieldMeta = { touched: hasError, error: errorText };

        return (
          <>
            <div onBlur={input.onBlur} className={css.uploadAvatarWrapper}>
              <label className={css.label} htmlFor={id}>
                {label}
              </label>
              <input
                ref={fileInputRef}
                accept={accept}
                id={id}
                name={name}
                className={css.uploadAvatarInput}
                disabled={uploadInProgress}
                onChange={(e) => onChange(e, input)}
                type={type}
                style={{ display: 'none' }}
              />
              <div className={css.uploadContainer}>
                {uploadInProgress
                  ? <IconSpinner />
                  : image ? (
                    <div className={css.uploadPreview}>
                      <img src={image} alt="Uploaded" className={css.uploadedImage} />
                      <p className={css.fileName}>{fileName}</p>
                    </div>
                  ) : (
                    <p className={css.uploadTextLabel}>
                      {placeholder}
                    </p>
                  )}
                <button
                  type="button"
                  className={css.uploadButton}
                  onClick={handleFileUploadClick}
                  disabled={uploadInProgress}
                >
                  <img src={uploadImg} alt="upload" width="20px" height="20px" />
                  <p className={css.uploadText}>
                    {uploadInProgress ? 'Uploading...' : 'Upload File'}
                  </p>
                </button>
                {!uploadInProgress && <ValidationError fieldMeta={fieldMeta} />}
              </div>
              {uploadImageError && (
                <div className={css.error}>
                  {uploadImageError}
                </div>
              )}
              <p className={css.imageDimension}>
                {dimensionInfo}
              </p>
            </div>
          </>
        );
      }}
    </Field>
  );
};

UserFieldImageUpload.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
};

UserFieldImageUpload.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  formName: string.isRequired,
  userTypeConfig: propTypes.userType.isRequired,
  intl: intlShape.isRequired,
};

export default UserFieldImageUpload;

