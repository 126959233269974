import React, { useEffect, useState } from 'react';
import { SCHEMA_TYPE_ENUM } from '../../util/types';
import { storeHours } from './StoreHours';
import css from "./SelectHours.module.css"
import { Field } from 'react-final-form';
import ValidationError from '../ValidationError/ValidationError';

const SelectHours = props => {
  const { value, fieldProps, name, form } = props;

  const daysOfWeek = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  fieldProps.fieldConfig.schemaType = SCHEMA_TYPE_ENUM;
  fieldProps.fieldConfig.enumOptions = storeHours;

  const [selectedHours, setSelectedHours] = useState({
    Monday: { selected: true, from: '0900', to: '1700' },
    Tuesday: { selected: true, from: '0900', to: '1700' },
    Wednesday: { selected: true, from: '0900', to: '1700' },
    Thursday: { selected: true, from: '0900', to: '1700' },
    Friday: { selected: true, from: '0900', to: '1700' },
    Saturday: { selected: false, from: '0900', to: '1700' },
    Sunday: { selected: false, from: '1330', to: '1630' },
  });

  const formatTime = (time) => {
    if (time.includes(':')) {
      const [hourMinute, period] = time.split(/(AM|PM)/i);
      const [hour, minute] = hourMinute.split(':');
      const hourInt = parseInt(hour, 10);
      return `${hourInt}:${minute} ${period.trim().toUpperCase()}`;
    }
    if (time.length === 4 && !isNaN(time)) {
      const hour = parseInt(time.slice(0, 2), 10);
      const minute = time.slice(2);
      const ampm = hour >= 12 ? 'PM' : 'AM';
      const hourIn12HrFormat = hour % 12 || 12;
      return `${hourIn12HrFormat}:${minute} ${ampm}`;
    }
    return '';
  };

  const getFormattedSelectedHours = (updatedSelectedHours) => {
    const groupedDays = groupDaysWithSameHours(updatedSelectedHours);
    const formattedSelectedHours = groupedDays.reduce((concate, group) => {
      if (group.length > 1) {
        return concate + `${group[0]} to ${group[group.length - 1]}: ${formatTime(updatedSelectedHours[group[0]].from)}-${formatTime(updatedSelectedHours[group[0]].to)}\n`;
      } else if (group.length === 1 && updatedSelectedHours[group[0]].selected) {
        return concate + `${group[0]}: ${formatTime(updatedSelectedHours[group[0]].from)}-${formatTime(updatedSelectedHours[group[0]].to)}\n`;
      } else {
        return concate;
      }
    }, '');

    return formattedSelectedHours;
  }

  const saveSelectedHours = (input, updatedSelectedHours) => {
    const formattedSelectedHours = getFormattedSelectedHours(updatedSelectedHours)
    setSelectedHours(updatedSelectedHours);
    input.onChange(formattedSelectedHours.trim());
  };

  const handleCheckboxChange = (day, input) => {
    const updatedSelectedHours = {
      ...selectedHours,
      [day]: {
        ...selectedHours[day],
        selected: !selectedHours[day].selected,
      }
    }
    saveSelectedHours(input, updatedSelectedHours);
    form.change('hoursOperation', updatedSelectedHours)
    form.change(day, !selectedHours[day].selected)
  };

  const handleTimeChange = (day, period, value, input) => {
    const updatedSelectedHours = {
      ...selectedHours,
      [day]: {
        ...selectedHours[day],
        [period]: value,
      }
    };
    form.change('hoursOperation', updatedSelectedHours)
    saveSelectedHours(input, updatedSelectedHours);
  };

  const groupDaysWithSameHours = (selectedHours) => {
    const groups = [];
    let tempGroup = [];

    daysOfWeek.forEach((day, index) => {
      const currentDay = selectedHours[day];
      if (tempGroup.length === 0) {
        tempGroup.push(day);
      } else {
        const lastDay = selectedHours[tempGroup[tempGroup.length - 1]];
        if (
          lastDay.selected &&
          currentDay.selected &&
          lastDay.from === currentDay.from &&
          lastDay.to === currentDay.to
        ) {
          tempGroup.push(day);
        } else {
          groups.push([...tempGroup]);
          tempGroup = [day];
        }
      }

      if (index === daysOfWeek.length - 1 && tempGroup.length) {
        groups.push([...tempGroup]);
      }
    });

    return groups;
  };

  const renderChoiceText = () => {
    const groupedDays = groupDaysWithSameHours(selectedHours);
    return groupedDays.map((group, index) => {
      if (group.length > 1) {
        return (
          <p key={index} className={css.choiseTimeData}>
            {group[0]} to {group[group.length - 1]}: {formatTime(selectedHours[group[0]].from)}-{formatTime(selectedHours[group[0]].to)}
          </p>
        );
      } else {
        return selectedHours[group[0]]?.selected
          ? <p key={index} className={css.choiseTimeData}>
            {group[0]}: {formatTime(selectedHours[group[0]].from)}-{formatTime(selectedHours[group[0]].to)}
          </p>
          : null
      }
    });
  };

  const instanceId = Math.random().toString(36).substring(7);

  useEffect(() => {
    if (value) {
      setSelectedHours(value);
    }
  }, [value]);

  const timeIsValid = (from, to) => {
    const convertToMinutes = (time) => {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + (minutes || 0);
    };

    const normalizeTime = (time) => {
      if (time.length === 4) {
        return `${time.slice(0, 2)}:${time.slice(2)}`;
      }
      return time;
    };

    from = normalizeTime(from);
    to = normalizeTime(to);

    const fromMinutes = convertToMinutes(from);
    const toMinutes = convertToMinutes(to);

    return fromMinutes > toMinutes;
  };

  const validateTimes = (schedule) => {
    const errors = [];

    Object.keys(schedule).forEach((day) => {
      const { from, to } = schedule[day];
      if (from && to && timeIsValid(from, to)) {
        errors.length = 0;
        errors.push('Invalid time frame: the end time must be later than the start time.')
      }
    });

    return errors;
  };


  return (
    <div className={css.hoursMain}>
      <Field
        id={name}
        name={name}
        {...props}
      >
        {({ input, meta }) => {
          const { invalid, error } = meta;
          const hasError = !!(invalid && error);

          const errorText = "Your store’s open days and hours are required!" || error;
          const fieldMeta = { touched: hasError, error: errorText };

          useEffect(() => {
            saveSelectedHours(input, selectedHours)
          }, [])

          useEffect(() => {
            setTimeout(() => {
              daysOfWeek?.forEach(day => {
                form.change(`open_${day}`, '0000')
                form.change(`close_${day}`, '0000')
              })
            }, 1000 * 2)
          }, []);
          return (
            <div className={css.storeHours}>
              {daysOfWeek?.map((day, index) => {
                const openKey = `${index}-open-${instanceId}`;
                const closeKey = `${index}-close-${instanceId}`;

                const openName = 'open_' + day.toLowerCase();
                const closeName = 'close_' + day.toLowerCase();

                const createFilterOptions = options =>
                  options.map(o => ({ key: `${o.option || o.key}`, label: o.label24 }));
                const { enumOptions = [] } = fieldProps.fieldConfig || {};
                const filterOptions = createFilterOptions(enumOptions);

                return (
                  <div key={index} className={css.hoursContainer}>
                    <label className={css.containerHours}>
                      <p>{day}</p>
                      <input
                        type="checkbox"
                        checked={selectedHours[day].selected}
                        onChange={() => handleCheckboxChange(day, input)}
                      />
                      <span className={css.checkmark}></span>
                    </label>

                    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', padding: "0 17px" }}>
                      <div className={css.hoursDurationMain}>
                        <p className={css.hoursDuration}>from</p>
                        <div className={css.hoursSelectMain}>
                          <div className={css.hoursSelect}>
                            <select
                              name={openName}
                              key={openKey}
                              value={selectedHours[day].from}
                              onChange={(e) => handleTimeChange(day, 'from', e.target.value, input)}
                              id={openKey}
                              className={css.hoursSelectBox}
                            >
                              {filterOptions.map(optionConfig => {
                                const key = optionConfig.key;
                                return (
                                  <option key={key} value={key}>
                                    {optionConfig.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className={css.hoursDurationMain}>
                        <p className={css.hoursDuration}>to</p>
                        <div className={css.hoursSelectMain}>
                          <div className={css.hoursSelect}>
                            <select
                              key={closeKey}
                              name={closeName}
                              value={selectedHours[day].to}
                              onChange={(e) => handleTimeChange(day, 'to', e.target.value, input)}
                              id={closeKey}
                              className={css.hoursSelectBox}
                            >
                              {filterOptions.map(optionConfig => {
                                const key = optionConfig.key;
                                return (
                                  <option key={key} value={key}>
                                    {optionConfig.label}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              <ValidationError fieldMeta={fieldMeta} />
            </div>
          );
        }}
      </Field>
      <div style={{ padding: "0 8px" }} className={css.hoursTextContainer}>
        <div className={css.hoursBorder}></div>
        <h4 className={css.choiseTimeHead}>
          Your choice:
        </h4>
        <div className={css.errorContainer}>
          {validateTimes(selectedHours).map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </div>
        {renderChoiceText()}
      </div>
    </div>
  );
};

export default SelectHours;
