export const storeHours = [
  { key: '0000', label: '12:00AM', label24: '00:00' },
  { key: '0030', label: '12:30AM', label24: '00:30' },
  { key: '0100', label: '1:00AM', label24: '01:00' },
  { key: '0130', label: '1:30AM', label24: '01:30' },
  { key: '0200', label: '2:00AM', label24: '02:00' },
  { key: '0230', label: '2:30AM', label24: '02:30' },
  { key: '0300', label: '3:00AM', label24: '03:00' },
  { key: '0330', label: '3:30AM', label24: '03:30' },
  { key: '0400', label: '4:00AM', label24: '04:00' },
  { key: '0430', label: '4:30AM', label24: '04:30' },
  { key: '0500', label: '5:00AM', label24: '05:00' },
  { key: '0530', label: '5:30AM', label24: '05:30' },
  { key: '0600', label: '6:00AM', label24: '06:00' },
  { key: '0630', label: '6:30AM', label24: '06:30' },
  { key: '0700', label: '7:00AM', label24: '07:00' },
  { key: '0730', label: '7:30AM', label24: '07:30' },
  { key: '0800', label: '8:00AM', label24: '08:00' },
  { key: '0830', label: '8:30AM', label24: '08:30' },
  { key: '0900', label: '9:00AM', label24: '09:00' },
  { key: '0930', label: '9:30AM', label24: '09:30' },
  { key: '1000', label: '10:00AM', label24: '10:00' },
  { key: '1030', label: '10:30AM', label24: '10:30' },
  { key: '1100', label: '11:00AM', label24: '11:00' },
  { key: '1130', label: '11:30AM', label24: '11:30' },
  { key: '1200', label: '12:00PM', label24: '12:00' },
  { key: '1230', label: '12:30PM', label24: '12:30' },
  { key: '1300', label: '1:00PM', label24: '13:00' },
  { key: '1330', label: '1:30PM', label24: '13:30' },
  { key: '1400', label: '2:00PM', label24: '14:00' },
  { key: '1430', label: '2:30PM', label24: '14:30' },
  { key: '1500', label: '3:00PM', label24: '15:00' },
  { key: '1530', label: '3:30PM', label24: '15:30' },
  { key: '1600', label: '4:00PM', label24: '16:00' },
  { key: '1630', label: '4:30PM', label24: '16:30' },
  { key: '1700', label: '5:00PM', label24: '17:00' },
  { key: '1730', label: '5:30PM', label24: '17:30' },
  { key: '1800', label: '6:00PM', label24: '18:00' },
  { key: '1830', label: '6:30PM', label24: '18:30' },
  { key: '1900', label: '7:00PM', label24: '19:00' },
  { key: '1930', label: '7:30PM', label24: '19:30' },
  { key: '2000', label: '8:00PM', label24: '20:00' },
  { key: '2030', label: '8:30PM', label24: '20:30' },
  { key: '2100', label: '9:00PM', label24: '21:00' },
  { key: '2130', label: '9:30PM', label24: '21:30' },
  { key: '2200', label: '10:00PM', label24: '22:00' },
  { key: '2230', label: '10:30PM', label24: '22:30' },
  { key: '2300', label: '11:00PM', label24: '23:00' },
  { key: '2330', label: '11:30PM', label24: '23:30' },
];
