import React from 'react';
import { FieldTextInput } from "../../components";
import * as validators from '../../util/validators';

import signupFormCSS from './SignupForm/SignupForm.module.css';
import profileSettingsFormCSS from '../ProfileSettingsPage/ProfileSettingsForm/ProfileSettingsForm.module.css';
import UserFieldImageUpload from './UserFieldImageUpload';
import SelectHours from '../../components/SelectHours/SelectHours';
import StoreLogo from '../ProfileSettingsPage/ProfileSettingsForm/StoreLogo';
import StorePhoto from '../ProfileSettingsPage/ProfileSettingsForm/StorePhoto';


const customPlaceholderMessageMapper = {
    'store_website': 'Enter the store website URL',
    'instagram_url': 'Store Instagram Profile URL',
    'facebook_url': 'Store Facebook Profile URL',
    'tiktok_url': 'Store TikTok Profile URL',
    'store_story': 'Use this section to tell customers all about your store and founding story. Your response will be limited to 775 characters.',
    'store_phone_number': 'Enter the store phone number',
    'store_owner_photo': 'Upload a photo of the store owner',
    'store_logo': 'Upload a photo of the store logo',
    'store_photo': 'Upload a photo of the store'
}

const fieldTypeMapper = {
    'store_story': { type: 'textarea', maxLength: '775' },
    'store_phone_number': { type: 'tel' }
}

const UserField = ({ userFieldProps, fieldKey, componentType, formId, value, form, validUrlFormat, intl, customLabel = "", page = "", dimensionsInfo = "" }) => {
    const instagramValid = validators.instagramFormatValid(
        intl && intl.formatMessage({
            id: 'SignupForm.instagramUrlInvalid',
        })
    );
    const tiktokValid = validators.tiktokFormatValid(
        intl && intl.formatMessage({
            id: 'SignupForm.tiktokUrlInvalid',
        })
    )
    const facebookValid = validators.facebookFormatValid(
        intl && intl.formatMessage({
            id: 'SignupForm.facebookUrlInvalid',
        })
    )
    const phoneValid = validators.phoneNumberFormatValid(
        intl && intl.formatMessage({
            id: 'SignupForm.phoneNumberInvalid',
        })
    )
    const fieldIndex = userFieldProps?.findIndex((fieldProps) => fieldProps.fieldConfig.key === fieldKey);
    const fieldElement = userFieldProps[fieldIndex];

    if (!fieldElement)
        return null;

    const { fieldConfig: { saveConfig } } = fieldElement || {};

    const originalLabel = saveConfig.label;
    const customPromptText = saveConfig.isRequired &&
        !originalLabel.endsWith('*')
        ? originalLabel + '*'
        : originalLabel;
    let customPlaceholderMessage = customPlaceholderMessageMapper[fieldKey] || 'Enter field here...';

    saveConfig.label = customPromptText;

    const { name, fieldConfig, defaultRequiredMessage } = fieldElement;
    const { isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
    const label = customLabel || customPromptText || fieldConfig?.label;

    const validateMaybe = isRequired
        ? { validate: validators.required(requiredMessage || defaultRequiredMessage) }
        : {};

    const validateUrlField = (urlValidator, isRequired) => (value) => {
        if (!value) {
            if (isRequired) {
                return validators.required(requiredMessage || defaultRequiredMessage)(value);
            }
            return undefined;
        }
        return urlValidator(value);
    };

    const getUrlFieldValidation = (fieldKey) => {
        const validateUrlFieldMapper = {
            'tiktok_url': validateUrlField(tiktokValid, isRequired),
            'instagram_url': validateUrlField(instagramValid, isRequired),
            'facebook_url': validateUrlField(facebookValid, isRequired),
            'store_phone_number': validateUrlField(phoneValid, isRequired),
        }
        return validators.composeValidators(validateUrlFieldMapper[fieldKey])
    }

    const placeholder =
        customPlaceholderMessage ||
        intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });
    const maxLengthMaybe = fieldTypeMapper?.[fieldKey]?.maxLength || fieldConfig.saveConfig?.maxLength || [];

    const css = page === "ProfileSettingPage" ? profileSettingsFormCSS : signupFormCSS;
    const renderComponent = {
        'validUrlFormat': <FieldTextInput
            className={`${css.customField} ${css.storeFields} ${['tiktok_url', 'instagram_url', 'facebook_url'].includes(fieldKey) ? css.textareaField : ''}`}
            id={formId ? `${formId}.${name}` : name}
            name={name}
            type={fieldTypeMapper?.[fieldKey]?.type || fieldConfig.schemaType}
            label={label}
            placeholder={placeholder}
            maxLength={maxLengthMaybe}
            validate={getUrlFieldValidation(fieldKey)}
        />,
        'phoneNumberField': <FieldTextInput
            className={`${css.customField}  ${css.customFieldPhoneNumber}  ${css.storeFields} ${fieldKey === 'store_story' ? css.textareaField : ''}`}
            id={formId ? `${formId}.${name}` : name}
            name={name}
            type={'number'}
            label={label}
            placeholder={placeholder}
            validate={getUrlFieldValidation(fieldKey)}
            maxLength={maxLengthMaybe}
        />,
        'simpleField': <FieldTextInput
            className={`${css.customField}  ${css.storeFields} ${fieldKey === 'store_story' ? css.textareaField : ''}`}
            id={formId ? `${formId}.${name}` : name}
            name={name}
            type={fieldTypeMapper?.[fieldKey]?.type || fieldConfig.schemaType}
            label={label}
            placeholder={placeholder}
            {...validateMaybe}
            maxLength={maxLengthMaybe}
        />,
        'imageUploadField': <UserFieldImageUpload
            {...validateMaybe}
            {...fieldElement}
            value={value}
            name={name}
            formId={formId}
            placeholder={placeholder}
            dimensionInfo={dimensionsInfo}
        />,
        'durationSelector': <>
            <label className={`${css.storeHoursLabel}`}>Select Store Hours of Operation*</label>
            <SelectHours
                {...validateMaybe}
                fieldProps={fieldElement}
                name={name}
                formId={formId}
                form={form}
                value={value}
            />
        </>,
        'profileSettingStoreLogo': <StoreLogo
            {...validateMaybe}
            {...fieldElement}
            value={value}
            name={name}
            formId={formId}
            placeholder={placeholder}
        />,
        'profileSettingStorePhoto': <StorePhoto
            {...validateMaybe}
            {...fieldElement}
            value={value}
            name={name}
            formId={formId}
            placeholder={placeholder}
        />,

    }

    return renderComponent[componentType];
}


export default UserField;

